import useSWR from "swr";
import axios from "axios";

let baseurl = "";
let args = {};
if (window.location.hostname === "localhost") {
  baseurl = "http://localhost:8000/";
} else {
  args = {
    refreshInterval: 5000,
  };
  baseurl = "https://bglick13-flash-bets-webapp-wrapper.modal.run/";
}

const fetcher = (url) =>
  axios
    .get(url)
    .then((res) => res.data)
    .catch((err) => console.log(err));
function useBets(id) {
  const { data, error } = useSWR(`${baseurl}todays_bets`, fetcher, args);
  return {
    data: data ? JSON.parse(data) : data,
    isLoading: !error && !data,
    isError: error,
  };
}

function useBacktestReport() {
  const { data, error } = useSWR(`${baseurl}latest_backtest`, fetcher);
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

function useCustomer(email) {
  const { data, error } = useSWR(
    email ? `${baseurl}customer/${email}` : null,
    fetcher
  );
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
}

export { useBets, useBacktestReport, useCustomer, baseurl };
