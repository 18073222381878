import "../App.css";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { baseurl } from "./api";

import { Link, Paper, IconButton, Skeleton } from "@mui/material";
import axios from "axios";
import useSWR from "swr";

import { Bookies } from "./Constants";

export default function BetCard({ match, profile, blur, isNew, key }) {
  return (
    <IconButton
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "95%",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "transparent",
        borderColor: "primary.main",
        margin: ".35rem",
      }}
      onClick={() => {
        console.log(profile);
        if (profile) {
          console.log("betting");
          window.open(match.bookie, "_blank");
          axios.post(`${baseurl}link_click`, {
            email: profile.email,
            link: match.bookie,
            event: match._id,
          });
        }
      }}
    >
      <Paper
        elevation={16}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "85%",
          padding: "1rem",
          borderRadius: "1rem",
          border: 1,
          borderColor: isNew ? "highlight.main" : "transparent",
          backgroundColor: "transparent",
          // borderColor: "primary.main",
        }}
      >
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          {blur ? (
            <Box sx={{ justifyContent: "center", alignItems: "center" }}>
              <Skeleton>
                <Typography height={100}>
                  Subscribe to see latest bets
                </Typography>
              </Skeleton>
              <Typography>Subscribe to see latest bets</Typography>
            </Box>
          ) : (
            `${match.event_type.toLocaleUpperCase()}: ${
              match._id
            }`.toUpperCase()
          )}
        </Typography>
        <Typography
          sx={{
            color: "text.primary",
          }}
        >
          {!blur ? (
            `${match.participant} @ ${match.odds} (EV = ${Math.round(
              match.edge * 100
            )}%)`.toLocaleUpperCase()
          ) : (
            <></>
          )}
        </Typography>
        <Typography
          sx={{
            color: "#FFFFFF",
          }}
        >
          {!blur ? `${Bookies[match.bookie]}` : <></>}
        </Typography>
      </Paper>
    </IconButton>
  );
}
