import React from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { Button } from "@mui/material";
export default function SignInView(props) {
  const { profile, setProfile } = props;
  const clientId =
    "504469807550-mbglkve78638dr42evq49prb9ho3gaip.apps.googleusercontent.com";

  const betsTab = "bets";

  const logOut = () => {
    setProfile(0);
    props.setAccessToken(null);
    props.setSelectedTab(betsTab);
  };
  const onSuccess = (res) => {
    setProfile(res.profileObj);
    props.setSelectedTab(betsTab);
    props.setAccessToken(res.tokenId);
  };
  const onFailure = (res) => {};

  return (
    <>
      {profile === 0 ? (
        // <Button>
        <GoogleLogin
          style={{ padding: "0px" }}
          clientId={clientId}
          buttonText="Sign in with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={"single_host_origin"}
          isSignedIn={true}
          responseType="code token"
          render={(renderProps) => (
            <Button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              Sign In
            </Button>
          )}
        />
      ) : (
        // </Button>
        <Button>
          <GoogleLogout
            clientId={clientId}
            buttonText="Log out"
            onLogoutSuccess={logOut}
            render={(renderProps) => (
              <Button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                Logout
              </Button>
            )}
          />
        </Button>
      )}
    </>
  );
}
