import { Box, Typography } from "@mui/material";

export default function About() {
  return (
    <Box sx={{ padding: "10px" }}>
      <Typography variant="h3">About</Typography>
      <Typography variant="p">
        Flash Bets was built by 2 software engineers who love sports betting.
        <br></br>
        We feel committed to finding the best odds for our bets, and we wanted
        to share our process with the world.
        <br></br>
        We use statistical models to identify +ev bets in realtime.
        <br></br>
        We are not predicting game outcomes - please only bet at the suggested
        prices.
        <br></br>
        We hope you enjoy using Flash Bets as much as we enjoy building it.
      </Typography>
      <Typography variant="h3">FAQ</Typography>
      <Typography pariant="p">
        Q: How does this work?<br></br>A: We monitor every US book to make sure
        we have up to the second odds. Every time a line changes, we update our
        statistical model to identify +ev opportunities<br></br>
        <br></br>
      </Typography>
      <Typography pariant="p">
        Q: How do I use it?<br></br>A: It's simple. Just keep this site open on
        the "Bets" page. When a new bet is available, the site will
        automatically update. Place your bet at the recommended book at the
        recommended price. Let us do all the thinking!<br></br>
        <br></br>
      </Typography>
      <Typography variant="p">
        Q: Why aren't you just using this?<br></br>A: We're based in
        Massachusetts. They're taking their sweet time legalizing sports
        betting, but we'll be putting our money where our mouth is as soon as
        they do.<br></br>
        <br></br>
      </Typography>
      <Typography variant="p">
        Q: Is it free?<br></br>
        A: Yes, it's free...for now. We never wanted to be a shill, but if it
        proves to be a valuable tool, we may explore payment options.
      </Typography>
    </Box>
  );
}
