import { useBets } from "../components/api";
import BetCard from "../components/BetCard";
import React from "react";
import Typography from "@mui/material/Typography";

import {
  Alert,
  Backdrop,
  Box,
  Button,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
} from "@mui/material";

function EmptyCard() {
  return (
    <Paper
      elevation={16}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        width: "85%",
        padding: "1rem",
        borderRadius: "1rem",
        backgroundColor: "transparent",
        borderColor: "primary.main",
      }}
    >
      <Typography
        sx={{
          color: "text.primary",
        }}
      >
        {"No available bet yet :("}
      </Typography>
      <Typography
        sx={{
          color: "#FFFFFF",
        }}
      >
        Check back later for a free bet.
      </Typography>
    </Paper>
  );
}

export default function BetView(props) {
  const bets = useBets();
  const [refreshingIn, setRefreshingIn] = React.useState(5);
  const [startIdx, setStartIdx] = React.useState(0);
  const [lastBetTimestamp, setLastBetTimestamp] = React.useState(null);
  // const [snackbarOpen, setSnackbarOpen] = React.useState(true);
  const [nNewBets, setNNewBets] = React.useState(0);

  React.useEffect(() => {
    if (bets.data) {
      if (bets.data[0].date_created !== lastBetTimestamp) {
        // setSnackbarOpen(true);
        if (lastBetTimestamp) {
          setNNewBets(nNewBets + 1);
        }

        setLastBetTimestamp(bets.data[0].date_created);
      }
    }
  }, [bets.data]);

  React.useEffect(() => {
    if (
      props.customer.data?.data.length ||
      props.accessCode === "dan@unabated.com"
    ) {
      setStartIdx(0);
    } else {
      setStartIdx(2);
    }
  }, [props.customer.data, props.accessCode]);
  React.useEffect(() => {
    const interval = setInterval(() => {
      if (refreshingIn > 0) {
        setRefreshingIn(refreshingIn - 1);
      } else {
        setRefreshingIn(5);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [refreshingIn]);

  const emptyBet = {};

  return (
    <Box
      sx={{ padding: "4px" }}
      onFocus={() => {
        setNNewBets(0);
      }}
    >
      <Typography variant="p">
        {`Refreshing in ${refreshingIn}s`} <br></br>
      </Typography>
      {props.customer.data?.data.length ? (
        <></>
      ) : (
        <Typography variant="p">
          See your free picks below. Guest bet streams are delayed. Login and
          subscribe to see all bets in real time
        </Typography>
      )}
      {bets.isLoading ? (
        <></>
      ) : (
        <>
          {bets.data.length ? (
            bets.data.map((bet, index) => {
              if (index < startIdx) {
                return (
                  <BetCard
                    match={bet}
                    profile={props.profile}
                    blur={true}
                    isNew={index < nNewBets}
                    key={index}
                  />
                );
              } else {
                return (
                  <BetCard
                    match={bet}
                    profile={props.profile}
                    blur={false}
                    isNew={index < nNewBets}
                    key={index}
                  />
                );
              }
            })
          ) : (
            <EmptyCard />
          )}
        </>
      )}
      <Snackbar open={nNewBets >= 1}>
        <Stack>
          {nNewBets >= 1 ? (
            <Alert severity="info">{`You have ${nNewBets} new bets!`}</Alert>
          ) : (
            <></>
          )}
        </Stack>
      </Snackbar>
    </Box>
  );
}
