export const Bookies = {
  "https://www.barstoolsportsbook.com/sports/": "Barstool",
  "https://pa.betrivers.com/?l=RiversPittsburgh&page=sportsbook&feed=live_upcoming#home":
    "BetRivers",
  "https://pa.twinspires.com/sports.shtml#filter/": "BetAmerica",
  "https://sports.nj.betmgm.com/en/sports/": "BetMGM",
  "https://nj.pointsbet.com/": "Pointsbet",
  "https://nj.foxbet.com/#/": "Foxbet",
  "https://sportsbook.fanduel.com/": "FanDuel",
  "https://sportsbook.draftkings.com/": "DraftKings",
  "https://www.williamhill.com/us/nj/bet/": "William Hill",
  "https://nj.unibet.com/sports/": "Unibet",
  "https://sportsbetrhodeisland.com/": "RI Sportsbook",
};
