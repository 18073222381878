import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Alert,
  AppBar,
  Container,
  Link,
  Paper,
  Snackbar,
  Toolbar,
} from "@mui/material";
import axios from "axios";
import BetView from "./views/BetView";
import BacktestReport from "./components/BacktestReport";
import About from "./components/About";
import BoltIcon from "@mui/icons-material/Bolt";
import Typography from "@mui/material/Typography";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { useCustomer } from "./components/api";
import SignInView from "./views/SignInView";
import Divider from "@mui/material/Divider";
import { inject } from "@vercel/analytics";
// Make sure to call this only once in your app
inject();

const theme = createTheme({
  palette: {
    text: {
      primary: "rgba(98,210,97,0.75)",
      secondary: "rgba(178,236,170,0.63)",
    },
    primary: { main: "#f5f5f5" },
    secondary: { main: "#000000" },
    highlight: { main: "#62d261" },
  },
  typography: {
    fontFamily: "Inconsolata",
  },
});
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function PricingTable() {
  return (
    <Paper
      elevation={3}
      sx={{ width: "75%", "background-color": "transparent" }}
    >
      <p>
        Guests get one free bet. Please consider subscribing to get access to
        all bets. Make sure you sign in above with the same e-mail you subscribe
        with
      </p>
      <stripe-pricing-table
        pricing-table-id="prctbl_1LzRyXLRvyliYwIvEWphXdpx"
        publishable-key="pk_live_51Lz1d6LRvyliYwIvSBblzcCTlTiYFEDH42sGEze1ZnifWlT9kTVd39BLss5mVOMTFvizWoa4axVLlCo4jRDdFj3v008xUaChEZ"
      ></stripe-pricing-table>
    </Paper>
  );
}

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function App() {
  const clientId =
    "504469807550-mbglkve78638dr42evq49prb9ho3gaip.apps.googleusercontent.com";

  React.useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);
  const [startColor, setStartColor] = React.useState("#161616");
  const [endColor, setEndColor] = React.useState("#3d3d3d");
  const [selectedTab, setSelectedTab] = React.useState("bets");
  const [profile, setProfile] = React.useState(0);
  const [accessCode, setAccessCode] = React.useState("");
  const [accessToken, setAccessToken] = React.useState(null);
  const customer = useCustomer(accessToken ? accessToken : null);

  return (
    // Add a linear gradient background
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          minHeight: "100vh",
          width: "100%",
          backgroundImage: `linear-gradient(45deg, ${startColor} 30%, ${endColor} 90%)`,
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300&display=swap"
          rel="stylesheet"
        />
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <AppBar
            position="static"
            color="transparent"
            sx={{ width: "100%", flexDirection: "row" }}
          >
            <Grid item>
              <Toolbar disableGutters>
                {/* <BoltIcon sx={{ color: "primary.main", fontSize: "2rem" }} /> */}
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    marginLeft: "10px",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  BETS
                </Typography>
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue) => {
                    setSelectedTab(newValue);
                  }}
                  textColor="primary"
                  aria-label="nav tabs example"
                >
                  <Tab value={"bets"} label="Bets" {...a11yProps(0)} />
                  <Tab
                    value={"past results"}
                    label="Past Results"
                    {...a11yProps(1)}
                  />
                  <Tab
                    value={"account"}
                    label="Account"
                    {...a11yProps(2)}
                    onClick={() =>
                      openInNewTab(
                        "https://billing.stripe.com/p/login/3csaHggjjb1j83S000"
                      )
                    }
                  />
                  <Tab
                    label={
                      <SignInView
                        profile={profile}
                        setProfile={setProfile}
                        setSelectedTab={setSelectedTab}
                        accessToken={accessToken}
                        setAccessToken={setAccessToken}
                      />
                    }
                  ></Tab>
                </Tabs>
                {/* <TextField
                  label="Access Code"
                  variant="outlined"
                  onChange={(event) => {
                    setAccessCode(event.target.value);
                  }}
                /> */}
              </Toolbar>
            </Grid>
          </AppBar>
        </Box>

        {customer.data?.data.length || accessCode === "dan@unabated.com" ? (
          <></>
        ) : (
          <PricingTable />
        )}

        {selectedTab === "bets" && (
          <Grid item xs={12}>
            <BetView
              customer={customer}
              profile={profile}
              accessCode={accessCode}
            />
          </Grid>
        )}
        {selectedTab === "past results" && <BacktestReport />}
        <About />
      </Grid>
    </ThemeProvider>
  );
}

export default App;
