import { useBacktestReport } from "./api";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { Typography } from "@mui/material";

export default function BacktestReport() {
  const backtestReport = useBacktestReport();
  return (
    <>
      {backtestReport.data ? (
        <>
          <Typography
            variant="h4"
            sx={{ padding: "4px" }}
          >{`${backtestReport.data.start_date} - ${backtestReport.data.end_date}`}</Typography>
          <Typography variant="h5" sx={{ padding: "4px" }}>{`Total Bets: ${
            backtestReport.data.num_bets
          } ROI: ${Math.round(
            backtestReport.data.roi * 100
          )}% Hit Rate: ${Math.round(
            backtestReport.data.win_rate * 100
          )}% Average Odds: ${Math.round(
            backtestReport.data.avg_odds * 100
          )}%`}</Typography>
          <Typography variant="h5">{`Returns per Book:`}</Typography>
          {Object.entries(backtestReport.data.returns_by_bookie).map(
            ([key, value]) => {
              return (
                <Typography variant="p">{`${key
                  .replace("https://", "")
                  .split(".")
                  .slice(-2, -1)}: $${Math.round(value)}`}</Typography>
              );
            }
          )}
        </>
      ) : (
        <CircularProgress />
      )}
    </>

    // <div>
    //   <h1>Backtest Report</h1>
    //   <p>
    //     This page is for displaying the results of a backtest. The backtest is
    //     performed by the{" "}
    //   </p>
    // </div>
  );
}
